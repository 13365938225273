<template>
  <div class="flex flex-col w-full lg:w-1/2 py-24 lg:pr-20">
    <div class="flex justify-between items-center w-full mb-16">
      <div class="w-128">
        Crypto Ninja
      </div>

      <AppButton :to="{ name: routeNames.login }">Sign In</AppButton>
    </div>

    <div class="w-full max-w-lg m-auto lg:mx-0">
      <Form
        :validation-schema="resetPasswordSchema"
        autocomplete="off"
        @submit="onSubmitForm"
      >
        <span class="text-14 text-blueGray-400">Reset password</span>
        <h4 class="mb-24 text-24">Enter your new password</h4>

        <AppInput
          id="password"
          v-model="resetForm.password"
          type="password"
          name="password"
          placeholder=""
          label="New password"
          autofocus
          required
        />

        <AppInput
          id="confirmPassword"
          v-model="resetForm.confirmPassword"
          type="password"
          name="confirmPassword"
          placeholder=""
          label="Confirm new password"
          autofocus
          required
        />

        <AppButton
          :loading="loading"
          variant="primary"
          type="submit"
          class="w-full"
        >
          Submit
        </AppButton>
      </Form>
    </div>
  </div>
</template>

<script >
import { defineComponent, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Form } from 'vee-validate'

import { resetPasswordSchema } from '@/core/schemas'
import { routeNames } from '@/router'
import { notification } from '@/core/helpers'
import { authService } from '@/services'

import AppInput from '@/components/stateless/AppInput.vue'
import AppButton from '@/components/stateless/AppButton.vue'

export default defineComponent({
  name: 'Reset',

  components: { Form, AppButton, AppInput },

  beforeRouteEnter (to, from, next) {
    const signature = !!to.query?.signature
    const expire = !!to.query?.expire

    if (!signature || !expire) {
      next({ name: routeNames.login })
    }

    next()
  },

  setup () {
    const router = useRouter()
    const route = useRoute()

    const loading = ref(false)
    const resetForm = reactive({
      password: null,
      confirmPassword: null
    })

    const onSubmitForm = () => {
      loading.value = true

      const email = route.query.email

      const formData = {
        email,
        signature: route.query.signature,
        expire: route.query.expire,
        password: resetForm.password,
        confirmPassword: resetForm.confirmPassword
      }

      authService.reset(formData)
        .then(() => {
          notification({
            type: 'success',
            title: 'Success',
            message: 'You have successfully updated your password.'
          })

          router.push({ name: routeNames.login })
        })
        .catch((err) => notification({ title: 'Reset password error', errorCode: err.error.code }))
        .finally(() => (loading.value = false))
    }

    return {
      loading,
      routeNames,
      resetPasswordSchema,
      resetForm,
      onSubmitForm
    }
  }
})
</script>
